import "./src/styles/fonts.css"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (window.ga) {
    window.ga("send", "pageview", location.pathname)
  }
}
